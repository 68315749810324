// console.log("Programming loaded");

/*
 ** Tabs
 */

document.addEventListener("DOMContentLoaded", function () {
    const tabs = document.querySelectorAll(".programming-date__tab");
    const sections = document.querySelectorAll(".festivals-programming");

    if (tabs.length > 0 && sections.length > 0) {
        tabs.forEach((tab) => {
            tab.addEventListener("click", function () {
                const selectedDate = this.getAttribute("programming-data-date");

                sections.forEach((section) => {
                    section.style.display =
                        section.getAttribute("programming-data-date") ===
                        selectedDate
                            ? "block"
                            : "none";
                });

                tabs.forEach((tab) => tab.classList.remove("active"));
                this.classList.add("active");
            });
        });
    }
});

/*
 ** Collapsible
 */

document.addEventListener("DOMContentLoaded", function () {
    const programmingCollapsibleButton = document.querySelectorAll(
        ".programming-collapsible__button"
    );
    const programmingCollapsible = document.querySelectorAll(
        ".programming-collapsible"
    );

    programmingCollapsibleButton.forEach((button, index) => {
        button.addEventListener("click", () => {
            programmingCollapsible[index].classList.toggle(
                "programming-collapsible__active"
            );
            if (
                programmingCollapsible[index].classList.contains(
                    "programming-collapsible__active"
                )
            ) {
                button.innerHTML =
                    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11Z" fill="white"/></svg>';
            } else {
                button.innerHTML =
                    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 11.0001H13V5.00012C13 4.44784 12.5523 4.00012 12 4.00012C11.4477 4.00012 11 4.44784 11 5.00012V11.0001H5C4.44772 11.0001 4 11.4478 4 12.0001C4 12.5524 4.44772 13.0001 5 13.0001H11V19.0001C11 19.5524 11.4477 20.0001 12 20.0001C12.5523 20.0001 13 19.5524 13 19.0001V13.0001H19C19.5523 13.0001 20 12.5524 20 12.0001C20 11.4478 19.5523 11.0001 19 11.0001Z" fill="white"/></svg>';
            }
        });
    });
});

/*
 ** Popup
 */

 document.addEventListener("DOMContentLoaded", function () {
    const sections = document.querySelectorAll(".festivals-programming");

    sections.forEach((section) => {
        const timelines = section.querySelectorAll(".timeline-event");

        timelines.forEach((timeline) => {
            const speakers = timeline.querySelectorAll(".programming-collapsible__speaker");
            const popup = timeline.querySelector(".popup-speaker");
            const overlay = timeline.querySelector(".popup-overlay");
            const closeButton = timeline.querySelector(".popup-close");

            speakers.forEach((speaker) => {
                speaker.addEventListener("click", function () {
                    const speakerId = speaker.getAttribute("data-speaker-id");

                    // Cacher d'abord tous les contenus de popup
                    const allSpeakerContents = popup.querySelectorAll(".popup-speaker__content");
                    allSpeakerContents.forEach(content => {
                        content.style.display = "none";
                    });

                    // Afficher le contenu du speaker sélectionné
                    const speakerContent = popup.querySelector(`[data-speaker-id="${speakerId}"]`);
                    if (speakerContent) {
                        speakerContent.style.display = "flex";
                        popup.style.display = "flex";
                        overlay.style.display = "block";
                    }
                });
            });

            function closePopup() {
                popup.style.display = "none";
                overlay.style.display = "none";
                // Cacher tous les contenus de popup
                const allSpeakerContents = popup.querySelectorAll(".popup-speaker__content");
                allSpeakerContents.forEach(content => {
                    content.style.display = "none";
                });
            }

            closeButton.addEventListener("click", closePopup);
            overlay.addEventListener("click", closePopup);
        });
    });
});

/**
 * Filters
 */
(function($) {

    document.addEventListener("DOMContentLoaded", function () {
        var NoneMode = Isotope.LayoutMode.create('none');
        function filterAndSearch(filter_classes)
        {
            var text = $('.quicksearch').val();
            if (filter_classes) {
                var inclusives = filter_classes.split(',');
            }
            var $this = $(this)
            console.log($this.text());
            var checkText = text == '' ? true : $this.text().toLowerCase().includes(text.toLowerCase());
            var checkClass = true;
            if (filter_classes) {
                var checkClass = inclusives.length == 0 ? true : false;
                $.each(inclusives, function(index, c) {
                var _class = $this.hasClass(c)
                if (_class) {
                    checkClass = true;
                    return;
                }
                })
            }
            return checkText && checkClass
        }
        if ($('.festivals-programming').length > 0) {
            var gridIsotope = $('.festivals-programming .timeline');
            gridIsotope.isotope({
                layoutMode: 'none',
                itemSelector: '.timeline-event',
                filter: function() {return filterAndSearch.apply(this, [null])}
            });
            gridIsotope.isotope('on', 'arrangeComplete', function () {
                $('.festivals-programming__date').show();
                var count = 0;
                var $dates = $('.programming-date__tab');
                $dates.removeClass('active');
                $('.festivals-programming').show();
                $('.festivals-programming__date').each(function () {
                    var $this = $(this);
                    var $timeline_visible = $this.siblings('.timeline').children(':visible');
                    if ($timeline_visible.length > 0) {
                        $this.show();
                        $dates.eq(count).show();
                    }
                    else {
                        $this.hide();
                        $dates.eq(count).hide();
                    }
                    count++;
                });
                //$('.quicksearch').val('');

            });
            if ($('.selectReset').length > 0) {

                $('.selectReset .resetFilter').click(function () {

                    gridIsotope.isotope({ filter: '*' });
                    $('.quicksearch').val('');
                });
            }
            $('.festivals-template-single-festivals-programming .resetFilter').show();

            $('#sidebar_programming .quicksearch').keyup(function () {
                gridIsotope.isotope({ filter: function() {return filterAndSearch.apply(this, [null])} });
            });
        }
    });
})(jQuery);
